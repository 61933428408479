import React from 'react';
import { Button, Typography, useTheme } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import cloneDeep from 'lodash/cloneDeep';
import DocumentUploadButton from './DocumentUploadButton';
import AlertDialog from '../AlertDialog';
import { getUploadICon } from './helpers';

export type FilesObjectType = {
  file: File|undefined,
  type: 'liquidacion'|'cotizaciones',
  documentUploading: boolean,
  uploadSuccess: boolean,
  fileOk: boolean,
  inputRefs: React.RefObject<HTMLCanvasElement>[ ]
}

type MultipleDocumentsUploadButtonProps = {
  documentsQuantity: number,
  files: FilesObjectType[],
  handleSetFiles: (f: FilesObjectType[])=> void;
  loading: boolean
}

export default function MultipleDocumentsUploadDialog(props: MultipleDocumentsUploadButtonProps) {
  const {
    documentsQuantity, files, handleSetFiles, loading,
  } = props;
  const theme = useTheme();
  const [filesInitialState, setFilesInitalState] = React.useState<FilesObjectType[]>([]);
  React.useEffect(() => {
    const fillDocs: FilesObjectType[] = [];
    Array(documentsQuantity).fill(1).forEach(() => fillDocs.push({
      file: undefined,
      type: 'liquidacion',
      documentUploading: false,
      uploadSuccess: false,
      fileOk: false,
      inputRefs: [],
    }));

    handleSetFiles(fillDocs);
    setFilesInitalState(cloneDeep(fillDocs));
  }, [documentsQuantity]);
  const onCloseAction = () => {
    if (!files.every((file) => file.uploadSuccess)) {
      handleSetFiles(cloneDeep(filesInitialState));
    }
  };
  const onSubmit = () => {
    setFilesInitalState(cloneDeep(files));
  };

  const handleSetFile = (file: File|undefined, index: number) => {
    const newFileArray = [...files];
    newFileArray[index].file = file;
    handleSetFiles(newFileArray);
  };
  const handleSetFileOk = (index: number, status: boolean) => {
    const newFileArray = [...files];
    newFileArray[index].fileOk = status;
    handleSetFiles(newFileArray);
  };
  const handleSetFileInputRefs = (
    index: number,
    inputRefs: React.RefObject<HTMLCanvasElement>[],
  ) => {
    const newFileArray = [...files];
    files[index].inputRefs = inputRefs;
    handleSetFiles(newFileArray);
  };
  const getPastDates = (index: number, date: Date = new Date()) => {
    date.setMonth(date.getMonth() - (index + 1));
    return date.toLocaleDateString('es-CL', { month: '2-digit', year: 'numeric' });
  };
  return (
    documentsQuantity > 1
      ? (
        <div>
          <AlertDialog
            action={onSubmit}
            onCloseAction={onCloseAction}
            keepMounted
            closeAfterSubmit
            submitButtonLabel="Continuar"
            submitButtonDisabled={!files.every((file) => file?.fileOk ?? false)
            || files.every((file) => file?.uploadSuccess)}
            dialogTitle={(
              <Typography
                align="center"
                fontWeight="bold"
              >
                Subir liquidaciones
              </Typography>
)}
            dialogContent={(
              <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: 10 }}>
                {files.map((file, index) => (
                  <DocumentUploadButton
                    key={`${index * 2}_key`}
                    label={`Liquidación ${getPastDates(index)}`}
                    file={file.file}
                    handleSetFiles={(f) => handleSetFile(f ? f[0] : undefined, index)}
                    documentUploading={loading}
                    uploadSuccess={file?.uploadSuccess ?? false}
                    fileOk={(status) => handleSetFileOk(index, status)}
                    maxPages={2}
                    type="liquidacion"
                    handleSetFileInputRefs={(fileInputRefs) => handleSetFileInputRefs(
                      index,
                      fileInputRefs,
                    )}
                  />
                ))}
              </div>
)}
            button={(
              <Button
                data-pw="preloan-upload-multiple-documents-button"
                style={{ marginTop: 15 }}
                startIcon={files.every((file) => file.uploadSuccess)
                  ? <CheckCircle style={{ color: 'green' }} />
                  : getUploadICon(
                    loading,
                    files.every((file) => file.fileOk),
                    theme.palette.secondary.main,
                  )}
                variant="outlined"
              >
                Subir
                liquidaciones
              </Button>
)}
          />
          <Typography
            variant="caption"
            component="div"
            marginTop={1}
          >
            {files.reduce((partialSum, file) => partialSum + (file.fileOk ? 1 : 0), 0)}
            /
            {documentsQuantity}
            {' '}
            archivos subidos

          </Typography>
        </div>
      )
      : (
        <DocumentUploadButton
          label="Subir última liquidación"
          file={(files && files.length > 0) ? files[0].file : undefined}
          handleSetFiles={(f) => ((files && files.length > 0)
            ? handleSetFile(f ? f[0] : undefined, 0) : undefined)}
          documentUploading={loading}
          uploadSuccess={files[0]?.uploadSuccess ?? false}
          fileOk={(status) => handleSetFileOk(0, status)}
          maxPages={2}
          type="liquidacion"
          handleSetFileInputRefs={(fileInputRefs) => handleSetFileInputRefs(
            0,
            fileInputRefs,
          )}
        />
      )

  );
}
