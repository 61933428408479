import React from 'react';
import {
  Card, Typography,
} from '@mui/material';
import type { PreApproveType } from '../../types/preloan/v3/index';

type PreApproveCardProps = {
  preApprove: PreApproveType;
};

function monthsToYears(months: number) {
  return (months / 12).toLocaleString('es-cl');
}

export default function PreApproveCard(props: PreApproveCardProps) {
  const { preApprove } = props;

  const preApproveSummary = (PA: PreApproveType) => (
    <div>
      <div>
        Monto deuda máxima
        <Typography variant="body1" color="secondary">
          {PA && PA.maxAmount}
        </Typography>
      </div>
      <div>
        Plazo máximo
        <Typography variant="body1" color="secondary">
          {PA && monthsToYears(PA.maxPeriods)}
          {' '}
          <span>años</span>
        </Typography>
      </div>
      <div>
        Tasa de interés anual UF
        <Typography variant="body1" color="secondary">
          {PA && PA.rate.toLocaleString('es-cl', {
            style: 'percent',
            minimumFractionDigits: 3,
          })}
        </Typography>
      </div>
    </div>
  );

  return (
    <Card
      elevation={3}
      style={{
        margin: '1em auto auto',
        padding: '10px',
        width: '240px',
        textAlign: 'center',
      }}
    >
      {preApproveSummary(preApprove)}
    </Card>
  );
}
