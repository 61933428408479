import React from 'react';
import {
  CircularProgress, Typography, useTheme,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { FormSchemaV3, PLRStepV3 } from '../../../../../../../types/preloan/v3';
import PLRAlertDialog from '../../../../../PLRAlertDialog';
import type { PLRAlertState } from '../../../../../../../types/preloan';
import { partnerErrorRedirectURL } from '../../../formLogic';
import { getOrCreatePLR } from './helpers';
import analytics from '../../../../../../../utils/analytics';
import { useQuery } from '../../../../../../../utils/helpers';

type GetOrCreatePLRProps = {
    formSchema: FormSchemaV3
    handleSetPLRState:(state:PLRStepV3|'error'|false)=>void,
    plrState: PLRStepV3|'error'|false,
    handleCurrentData: (values: FormSchemaV3)=> void,
    dataLoading: boolean,
    handleSetDataLoading: (state:boolean)=> void,
}

export default function GetOrCreatePLR(props:GetOrCreatePLRProps) {
  const {
    formSchema,
    handleCurrentData,
    plrState,
    handleSetPLRState,
    dataLoading,
    handleSetDataLoading,
  } = props;
  const theme = useTheme();
  const [alertState, setAlertState] = React.useState<PLRAlertState|undefined>(undefined);
  const [signedLREmail, setSignedLREmail] = React.useState('');
  const handleSetAlertState = (state:PLRAlertState) => setAlertState(state);
  const handleSetSignedLREmail = (email:string) => setSignedLREmail(email);
  const query = useQuery();
  const history = useHistory();
  const searchParams = React.useMemo(() => query, [query]);

  React.useEffect(
    () => {
      setTimeout(
        () => getOrCreatePLR({
          origin: formSchema.origin,
          rut: formSchema.rut,
          captchaToken: formSchema.captchaToken,
          personalInfo: null,
          userInput: Object.values(formSchema.userInput).some((uInput) => uInput === '') ? null : formSchema.userInput,
          referralCode: formSchema.referralCode,
          handleSetPLRState,
          handleCurrentData,
          handleSetDataLoading,
          handleSetSignedLREmail,
          handleSetAlertState,
          formSchema,
          searchParams,
          history,
        }),
        1000,
      );
    },
    [],
  );
  React.useEffect(() => {
    if (plrState === 'error') {
      const redirectURL = partnerErrorRedirectURL(
        formSchema.origin,
        formSchema.rut,
        formSchema.partnerConfigurations,
      );
      if (redirectURL) {
        setTimeout(() => { window.location.href = redirectURL; }, 1000);
      }
    }
  }, [plrState]);

  const handlePostPersonalInfo = (personalInfo: FormSchemaV3['personalInfo']) => {
    handleSetDataLoading(true);
    setAlertState(undefined);
    setTimeout(
      () => getOrCreatePLR({
        origin: formSchema.origin,
        rut: formSchema.rut,
        captchaToken: formSchema.captchaToken,
        personalInfo,
        userInput: Object.values(formSchema.userInput).some((uInput) => uInput === '') ? null : formSchema.userInput,
        referralCode: formSchema.referralCode,
        handleSetPLRState,
        handleCurrentData,
        handleSetDataLoading,
        handleSetSignedLREmail,
        handleSetAlertState,
        formSchema,
        searchParams,
        history,
      }),
      2000,
    );
  };

  const getProduct = () => {
    if (formSchema.origin === 'tenpo') {
      return 'adelanto';
    }
    return 'crédito';
  };

  const getWelcomeTextStep = () => {
    if (formSchema.origin === 'IRentup') {
      return 'Queda poco para que evaluemos tu postulación de arriendo. ';
    }
    return `Tu perfil pre-califica para un ${getProduct()} ${formSchema.partnerProducts.some((product) => product === 'mortgage') ? 'hipotecario. ' : 'responsable. '}`;
  };

  const pageTrack = (plrDataLoading: boolean, plrAlertState: PLRAlertState|undefined) => {
    if (plrDataLoading) {
      analytics.page('PLR - ONBOARDING', 'RESULTADO PRELIMINAR');
    } else {
      switch (plrAlertState) {
        case 'userHasActiveLoan':
          analytics.page('PLR - REDIRECCION RUT', 'CREDITO ACTIVO');
          break;
        case 'userHasUnsignedLoanRequest':
          analytics.page('PLR - REDIRECCION RUT', 'SOLICITUD DE CREDITO SIN FIRMAR');
          break;
        case 'userHasSignedLoanRequest':
          analytics.page('PLR - REDIRECCION RUT', 'SOLICITUD DE CREDITO FIRMADA');
          break;
        case 'userHasMissingPersonalInfo':
          analytics.page('PLR - REDIRECCION RUT', 'RUT SIN INFORMACION');
          break;
        default:
          analytics.page('PLR - ONBOARDING', 'PRE-CALIFICACION');
          break;
      }
    }
  };

  React.useEffect(() => {
    if (alertState !== undefined) {
      pageTrack(dataLoading, alertState);
    }
  }, [dataLoading, alertState]);

  return (
    dataLoading ? (
      <>
        <br />
        <div style={{ marginTop: '10%' }}>
          <Typography>
            Por favor espera un momento, esto podría tomar unos segundos...
          </Typography>
          <br />
          <CircularProgress size={20} />
        </div>
        {(plrState === 'error') && (
          <Typography variant="caption" color="error">
            Ha ocurrido un error, por favor intente en otra ocasión o
            contáctenos
            al servicio al cliente.
          </Typography>
        )}
      </>
    )
      : (
        <div style={{
          padding: '5%',
          marginTop: '10%',
          borderRadius: 15,
          backgroundColor: theme.palette.gray.main,

        }}
        >
          <Typography
            gutterBottom
            variant="h6"
          >
            {alertState ? '¡Hola!' : '¡Felicidades!'}

          </Typography>
          <Typography variant="h6" component="div">
            {alertState
              ? (
                <div>
                  Según nuestros registros
                  existe un motivo
                  por el que
                  {' '}
                  <b style={{ color: theme.palette.primary.main }}>
                    no puedes continuar
                    la evaluación crediticia con nosotros.
                  </b>
                  {' '}
                  Por favor sigue las instrucciones
                  indicadas en el detalle.
                </div>
              )
              : (
                <div
                  data-pw="preloan-get-or-create-text"
                  style={{ marginTop: 20 }}
                >
                  ¡Vas por buen camino!
                  {' '}
                  <span style={{ color: theme.palette.secondary.main }}>
                    {getWelcomeTextStep()}
                  </span>
                  A continuación te pediremos más información para conocerte mejor.
                </div>
              )}
          </Typography>
          <br />
          {alertState
            && (
            <PLRAlertDialog
              alertState={alertState}
              email={signedLREmail}
              formSchema={formSchema}
              handlePostPersonalInfo={handlePostPersonalInfo}
            />
            )}
        </div>
      )

  );
}
