/* eslint-disable import/prefer-default-export */
export const demoUserReport = {
  createdAt: new Date().toISOString(),
  history: {
    score: { score: 403, probability: 0.1 },
    negotiableDebts: { debts: [] },
    detail: [
      {
        type: 'directDebt',
        amount: 1780647,
        source: 'CMF',
        debtTime: 'ACTIVE',
        product: 'Consumo',
        institution: 'Banco del Ejemplo',
      },
      {
        type: 'directDebt',
        amount: 560058,
        source: 'CMF',
        debtTime: 'ACTIVE',
        product: 'Consumo',
        institution: 'Banco Ejemplar',
      },
      {
        type: 'directDebt',
        amount: 1874764,
        source: 'CMF',
        debtTime: 'ACTIVE',
        product: 'Consumo',
        institution: 'Banco Securiting',
      },
    ],
    summary: {
      dmorosa: [
        {
          date: '2023-05-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-04-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-03-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-02-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-01-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-12-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-11-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-10-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-09-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-08-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-07-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-06-01T04:00:00.000Z',
          amount: 0,
        },
      ],
      dconsumo: [
        {
          date: '2023-05-01T04:00:00.000Z',
          amount: 42935,
        },
        {
          date: '2023-04-01T03:00:00.000Z',
          amount: 43239,
        },
        {
          date: '2023-03-01T03:00:00.000Z',
          amount: 39570,
        },
        {
          date: '2023-02-01T03:00:00.000Z',
          amount: 39568,
        },
        {
          date: '2023-01-01T03:00:00.000Z',
          amount: 42985,
        },
        {
          date: '2022-12-01T03:00:00.000Z',
          amount: 42159,
        },
        {
          date: '2022-11-01T03:00:00.000Z',
          amount: 42392,
        },
        {
          date: '2022-10-01T03:00:00.000Z',
          amount: 44568,
        },
        {
          date: '2022-09-01T04:00:00.000Z',
          amount: 46287,
        },
        {
          date: '2022-08-01T04:00:00.000Z',
          amount: 46041,
        },
        {
          date: '2022-07-01T04:00:00.000Z',
          amount: 47844,
        },
        {
          date: '2022-06-01T04:00:00.000Z',
          amount: 48273,
        },
      ],
      dvencida: [
        {
          date: '2023-05-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-04-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-03-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-02-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-01-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-12-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-11-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-10-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-09-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-08-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-07-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-06-01T04:00:00.000Z',
          amount: 0,
        },
      ],
      dvigente: [
        {
          date: '2023-05-01T04:00:00.000Z',
          amount: 42935,
        },
        {
          date: '2023-04-01T03:00:00.000Z',
          amount: 43239,
        },
        {
          date: '2023-03-01T03:00:00.000Z',
          amount: 39570,
        },
        {
          date: '2023-02-01T03:00:00.000Z',
          amount: 39568,
        },
        {
          date: '2023-01-01T03:00:00.000Z',
          amount: 42985,
        },
        {
          date: '2022-12-01T03:00:00.000Z',
          amount: 42159,
        },
        {
          date: '2022-11-01T03:00:00.000Z',
          amount: 42392,
        },
        {
          date: '2022-10-01T03:00:00.000Z',
          amount: 44568,
        },
        {
          date: '2022-09-01T04:00:00.000Z',
          amount: 46287,
        },
        {
          date: '2022-08-01T04:00:00.000Z',
          amount: 46041,
        },
        {
          date: '2022-07-01T04:00:00.000Z',
          amount: 47844,
        },
        {
          date: '2022-06-01T04:00:00.000Z',
          amount: 48273,
        },
      ],
      lcredito: [
        {
          date: '2023-05-01T04:00:00.000Z',
          amount: 8471,
        },
        {
          date: '2023-04-01T03:00:00.000Z',
          amount: 8718,
        },
        {
          date: '2023-03-01T03:00:00.000Z',
          amount: 13439,
        },
        {
          date: '2023-02-01T03:00:00.000Z',
          amount: 13971,
        },
        {
          date: '2023-01-01T03:00:00.000Z',
          amount: 11545,
        },
        {
          date: '2022-12-01T03:00:00.000Z',
          amount: 13558,
        },
        {
          date: '2022-11-01T03:00:00.000Z',
          amount: 14413,
        },
        {
          date: '2022-10-01T03:00:00.000Z',
          amount: 12924,
        },
        {
          date: '2022-09-01T04:00:00.000Z',
          amount: 11475,
        },
        {
          date: '2022-08-01T04:00:00.000Z',
          amount: 12666,
        },
        {
          date: '2022-07-01T04:00:00.000Z',
          amount: 12482,
        },
        {
          date: '2022-06-01T04:00:00.000Z',
          amount: 12563,
        },
      ],
      dacreedor: [
        {
          date: '2023-05-01T04:00:00.000Z',
          amount: 3,
        },
        {
          date: '2023-04-01T03:00:00.000Z',
          amount: 3,
        },
        {
          date: '2023-03-01T03:00:00.000Z',
          amount: 3,
        },
        {
          date: '2023-02-01T03:00:00.000Z',
          amount: 3,
        },
        {
          date: '2023-01-01T03:00:00.000Z',
          amount: 3,
        },
        {
          date: '2022-12-01T03:00:00.000Z',
          amount: 3,
        },
        {
          date: '2022-11-01T03:00:00.000Z',
          amount: 3,
        },
        {
          date: '2022-10-01T03:00:00.000Z',
          amount: 3,
        },
        {
          date: '2022-09-01T04:00:00.000Z',
          amount: 3,
        },
        {
          date: '2022-08-01T04:00:00.000Z',
          amount: 3,
        },
        {
          date: '2022-07-01T04:00:00.000Z',
          amount: 3,
        },
        {
          date: '2022-06-01T04:00:00.000Z',
          amount: 3,
        },
      ],
      dcomercial: [
        {
          date: '2023-05-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-04-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-03-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-02-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-01-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-12-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-11-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-10-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-09-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-08-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-07-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-06-01T04:00:00.000Z',
          amount: 0,
        },
      ],
      dinversion: [
        {
          date: '2023-05-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-04-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-03-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-02-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-01-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-12-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-11-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-10-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-09-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-08-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-07-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-06-01T04:00:00.000Z',
          amount: 0,
        },
      ],
      mexvencida: [
        {
          date: '2023-05-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-04-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-03-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-02-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-01-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-12-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-11-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-10-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-09-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-08-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-07-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-06-01T04:00:00.000Z',
          amount: 0,
        },
      ],
      mexvigente: [
        {
          date: '2023-05-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-04-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-03-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-02-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-01-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-12-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-11-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-10-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-09-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-08-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-07-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-06-01T04:00:00.000Z',
          amount: 0,
        },
      ],
      dhipotecaria: [
        {
          date: '2023-05-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-04-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-03-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-02-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-01-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-12-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-11-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-10-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-09-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-08-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-07-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-06-01T04:00:00.000Z',
          amount: 0,
        },
      ],
      dcontingentes: [
        {
          date: '2023-05-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-04-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-03-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-02-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-01-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-12-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-11-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-10-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-09-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-08-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-07-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-06-01T04:00:00.000Z',
          amount: 0,
        },
      ],
      leasingMorosa: [
        {
          date: '2023-05-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-04-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-03-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-02-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-01-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-12-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-11-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-10-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-09-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-08-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-07-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-06-01T04:00:00.000Z',
          amount: 0,
        },
      ],
      leasingVigente: [
        {
          date: '2023-05-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-04-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-03-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-02-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-01-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-12-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-11-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-10-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-09-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-08-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-07-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-06-01T04:00:00.000Z',
          amount: 0,
        },
      ],
      doperacionpacto: [
        {
          date: '2023-05-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-04-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-03-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-02-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-01-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-12-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-11-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-10-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-09-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-08-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-07-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-06-01T04:00:00.000Z',
          amount: 0,
        },
      ],
      castigada_directa: [
        {
          date: '2023-05-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-04-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-03-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-02-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-01-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-12-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-11-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-10-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-09-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-08-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-07-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-06-01T04:00:00.000Z',
          amount: 0,
        },
      ],
      indirecta_vencida: [
        {
          date: '2023-05-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-04-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-03-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-02-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-01-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-12-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-11-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-10-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-09-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-08-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-07-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-06-01T04:00:00.000Z',
          amount: 0,
        },
      ],
      indirecta_vigente: [
        {
          date: '2023-05-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-04-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-03-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-02-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-01-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-12-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-11-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-10-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-09-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-08-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-07-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-06-01T04:00:00.000Z',
          amount: 0,
        },
      ],
      castigada_indirecta: [
        {
          date: '2023-05-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-04-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-03-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-02-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2023-01-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-12-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-11-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-10-01T03:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-09-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-08-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-07-01T04:00:00.000Z',
          amount: 0,
        },
        {
          date: '2022-06-01T04:00:00.000Z',
          amount: 0,
        },
      ],
      monthlyDebtToIncome: 0.3,
      monthlyDebts: 300000,
      totalDebt: 0,
      totalCredit: 4215469,
    },
    userData: {
      name: 'MUÑOZ EJEMPLO BANCAMIN',
      idNumber: '12345678-9',
      nationality: 'C',
      maritalStatus: 'S',
    },
    createdAt: new Date().toISOString(),
  },
};
