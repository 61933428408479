import React from 'react';
import { Typography, useTheme } from '@mui/material';
import AlertDialog from '../../../../../../../components/AlertDialog';
import { FormSchemaV3 } from '../../../../../../../types/preloan/v3';
import { partnerErrorRedirectURL } from '../../../formLogic';

type LoanConditionsDialogProps = {
  triggerAlert: boolean;
  formSchema: FormSchemaV3;
  recommendedAmount:{amount: number,
    periods: number,
     installment: number,
     interestRate: number
   },
}

export default function LoanConditionsDialog(props:LoanConditionsDialogProps) {
  const { triggerAlert, formSchema, recommendedAmount } = props;
  const theme = useTheme();
  const action = () => {
    const redirectURL = partnerErrorRedirectURL(
      formSchema.origin,
      formSchema.rut,
      formSchema.partnerConfigurations,
    );
    if (redirectURL) {
      window.location.href = redirectURL;
    }
  };
  return (
    <AlertDialog
      isTriggered={triggerAlert}
      button={<div />}
      submitButtonLabel={formSchema.origin === 'brincus' ? 'Volver a Brincus' : 'Entendido'}
      dialogContent={
          (
            <div>
              <Typography
                variant="h4"
                align="center"
                gutterBottom
                fontWeight="bold"
                component="div"
              >
                ¡Hey!
              </Typography>
              <Typography
                variant="h6"
                align="center"
                gutterBottom
                component="div"
              >
                ¡Felicidades, tienes tu
                {' '}
                <b style={{ color: theme.palette.primary.main }}>
                  crédito pre-aprobado
                </b>
                !
                {' '}
                Sin embargo,
                {' '}
                <b>
                  las condiciones
                </b>
                {' '}
                que podemos ofrecerte según nuestra política de riesgo
                y acorde a tu perfil
                {' '}
                <b>
                  son distintas a las que solicitaste
                  {' '}
                </b>
                {' '}

              </Typography>
              <Typography
                variant="h6"
                align="center"
                component="div"
              >
                {
                formSchema.origin === 'brincus'
                  ? (
                    <>

                      Te invitamos a volver a Brincus y seleccionar
                      una cantidad menor de cursos a la solicitada
                      En tu caso, puedes optar a
                      {' '}
                      <b style={{ color: theme.palette.secondary.main }}>
                        un máximo de
                        {' '}
                        {recommendedAmount.amount / formSchema.loanParams.amountStepSize}
                        {' '}
                        plan
                        {(recommendedAmount.amount / formSchema.loanParams.amountStepSize) > 1
                          ? <span>es</span> : <span />}
                        {' '}
                        <span>
                          de colegio online con Brincus.
                        </span>
                      </b>
                    </>
                  )
                  : 'Te invitamos a revisar las condiciones y pasar a firmar si es que te interesan'
                }
              </Typography>
            </div>
          )

}
      action={action}
      centerButtons
      hideCloseButton
      closeAfterSubmit={!(formSchema.origin === 'brincus')}
    />

  );
}
