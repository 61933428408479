import React from 'react';
import {
  Grid, Paper, useTheme,
} from '@mui/material';
import useStyles from '../styles';

type LeadFormLeftGridProps = {
    xs?: number|false,
    lg?: number|false,
    children: React.ReactElement<any, any>,
}

const defaultProps = {
  xs: 12,
  lg: 5,
};

export default function LeadFormLeftGrid(props: LeadFormLeftGridProps) {
  const {
    xs, lg, children,
  } = props;
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Grid
      item
      xs={xs}
      lg={lg}
      component={Paper}
      style={{ backgroundColor: theme.palette.background.defaultSecondary }}
      borderRadius="10px"
      className={classes.leftGrid}
      square
      elevation={0}
    >
      {children}
    </Grid>
  );
}

LeadFormLeftGrid.defaultProps = defaultProps;
