import React from 'react';
import { useIntercom } from 'react-use-intercom';
import analytics from '../../utils/analytics';
import BancameButton from '../BancameButton';

type AgentContactButtonProps = {
  trackCallCTA?: string,
  hide?: boolean,
  reportError?: boolean,
  eventId?: string
}

const defaultProps = {
  trackCallCTA: 'form bancame',
  hide: false,
  reportError: false,
  eventId: '',
};

export default function AgentContactButton(props: AgentContactButtonProps) {
  const {
    trackCallCTA, hide, reportError, eventId,
  } = props;
  const { show: showIntercom, showNewMessage } = useIntercom();
  const handleOpenIntercom = () => {
    analytics.track(reportError ? 'Reportar error' : 'Contactar Agente', {
      cta: trackCallCTA,
      initiator: 'usuario',
    });
    if (reportError) {
      showNewMessage(`¡Hola! Ha ocurrido un error inesperado: El ID del error es: ${eventId}`);
    } else {
      showIntercom();
    }
  };
  return (
    hide
      ? <div />
      : (
        <div style={{ display: 'flex', placeContent: 'center' }}>
          <BancameButton
            onClick={handleOpenIntercom}
            label="Contactar a un agente"
            type="support"
            displayFlex
          />
        </div>
      )
  );
}

AgentContactButton.defaultProps = defaultProps;
