import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  // Simulator
  simulator: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      margin: 'auto',
      minWidth: 'auto',
      paddingTop: 0,
    },
  },
  slider_padding: {
    padding: '0 10%',
    [theme.breakpoints.down('md')]: {
      padding: '0 10px',
    },
  },
  simulatorTooltip: {
    tooltip: {
      backgroundColor: 'red !important',
    },
  },
  simulator__container: {
    marginTop: '1em',
    marginBottom: '1em',
  },
  simulatorContainer: {
    padding: '16px 0',
    width: '100%',
    backgroundColor: theme.palette.background.defaultSecondary,
  },
  paper_simulator: {
    marginLeft: '2%',
    marginRight: '2%',
    padding: '2%',
  },
}));

export default useStyles;
