import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import BackgroundPic0 from '../../../assets/background_isotipo_dark.svg';
import BackgroundPic from '../../../assets/background_forms_light_secondary.svg';

const useStyles = makeStyles((theme: Theme) => ({
  contentContainer: {
    paddingTop: '10%',
    backgroundColor: theme.palette.background.darkSecondary,
    minHeight: '100%',
    backgroundImage: `url(${BackgroundPic0})`,
    backgroundSize: '15%',
    backgroundPosition: 'center',
    [theme.breakpoints.down('md')]: {
      paddingTop: '2%',
    },
  },
  avatar: {
    width: 100,
    [theme.breakpoints.down('md')]: {
      width: 70,
    },
  },
  gridContainer: {
    marginTop: 10,
    paddingLeft: 15,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 5,
      paddingRight: 5,
      paddingBottom: 35,
      marginTop: 0,
    },
  },
  gridItem: {
    height: 400,
    borderRadius: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: `url(${BackgroundPic})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    [theme.breakpoints.down('md')]: {
      height: 230,
    },
  },
  buttonsContainer: {
    marginTop: 30,
    display: 'grid',
    columnGap: 10,
    gridTemplateColumns: '1fr 1fr',
    justifyItems: 'center',
  },
  backButton: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginTop: '5px',
    maxWidth: 250,
    '&:hover': {
      fontWeight: 'bold',
      color: theme.palette.secondary.main,
    },
  },
  title: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    width: 200,
    alignContent: 'center',
    [theme.breakpoints.down('md')]: {
      paddingTop: '15px',

    },
  },
}));

export default useStyles;
