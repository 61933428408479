import React from 'react';
import { Typography } from '@mui/material';
import cloneDeep from 'lodash/cloneDeep';
import type { FilesObjectType } from './MultipleDocumentsUploadDialog';
import { AWSUrl, postAWSDocument } from './helpers';
import useStyles from './styles';
import MultipleDocumentsUploadDialog from './MultipleDocumentsUploadDialog';
import DocumentUploadButton from './DocumentUploadButton';

export type PLRDocument = {
  plrDocumentId: string,
  url: AWSUrl,
}

type UploadDocumentsProps = {
  needsSettlement?: boolean,
  needsPensionStatement?: boolean,
  pensionStatementsRequired?: number,
  settlementsRequired?: number,
  getDocumentsUrl: (pensionQuoteExtension?: string, payslipExtension?: string)
    => Promise<{data:{
      cotizacionUrl: PLRDocument | undefined,
      liquidacionUrls: PLRDocument[] | undefined
    }
    }>,
  handleDocumentsOk: (documentsOk: boolean) => void,
  triggerUpload: boolean,
  handleUploadOk: () => void,
}
const defaultProps = {
  needsSettlement: true,
  needsPensionStatement: true,
  pensionStatementsRequired: 12,
  settlementsRequired: 1,
};

const getPath = (filename?: string) => {
  if (filename === undefined) return undefined;
  const path = filename.split('.');
  return path[path.length - 1];
};

export default function UploadDocuments(props: UploadDocumentsProps) {
  const {
    needsSettlement,
    needsPensionStatement,
    getDocumentsUrl,
    triggerUpload,
    handleUploadOk,
    handleDocumentsOk,
    settlementsRequired,
    pensionStatementsRequired,
  } = props;
  const classes = useStyles();
  const [filesOne, setFilesOne] = React.useState<FilesObjectType[]>([]);
  const handleSetFilesOne = (files: FilesObjectType[]) => setFilesOne(files);
  const [fileTwo, setFileTwo] = React.useState<File[] | undefined>(undefined);
  const handleSetFileTwo = (file: File[] | undefined) => setFileTwo(file);
  const [fileTwoOk, setFileTwoOk] = React.useState(false);

  const [filesOneUploading, setFileOnesLoading] = React.useState(false);
  const [fileTwoUploading, setFileTwoLoading] = React.useState(false);
  const [uploadSuccessTwo, setUploadSuccessTwo] = React.useState(false);

  const [fileTwoInputRefs,
    setFileTwoInputRefs] = React.useState<React.RefObject<HTMLCanvasElement>[][]>([]);

  const handleSetFileInputRefsTwo = (fileInputRefs: React.RefObject<HTMLCanvasElement>[]) => {
    setFileTwoInputRefs((o) => [...o, fileInputRefs]);
  };

  const [error, setError] = React.useState(false);

  const handlePensionStatementFileOk = (state: boolean) => setFileTwoOk(state);
  const getURLandUpload = async () => {
    setError(false);
    setFileOnesLoading(true);
    setFileTwoLoading(true);
    try {
      const payslipExt = filesOne && filesOne.length > 0 ? getPath(`${filesOne[0].file?.name}`) : undefined;
      const pensionQuoteExt = fileTwo && fileTwo.length > 0 ? getPath(`${fileTwo[0].name}`) : undefined;
      const { data } = await getDocumentsUrl(payslipExt, pensionQuoteExt);
      if (data.liquidacionUrls
        && data.liquidacionUrls.length > 0 && filesOne && filesOne.length > 0) {
        const toUploadFiles = filesOne.map(async (file, index) => {
          try {
            await postAWSDocument(
              file.inputRefs
          || [],
              file.file as File,
              (data.liquidacionUrls as PLRDocument[])[index],
            );
            return 'ok';
          } catch (e) {
            return 'error';
          }
        });
        const uploadFiles = await Promise.all(toUploadFiles);
        uploadFiles.forEach((state, index) => {
          filesOne[index].uploadSuccess = state === 'ok';
          return filesOne;
        });
        setFilesOne(cloneDeep(filesOne));
        const errorIndexes = uploadFiles
          .map((err, i) => (err === 'error' ? i : -1))
          .filter((index) => index !== -1);
        if (errorIndexes.length > 0) {
          setError(true);
        }
      }
      if (data.cotizacionUrl && fileTwo && fileTwo.length > 0) {
        const res = await postAWSDocument(
          fileTwoInputRefs[0] || [],
          fileTwo[0],

          data.cotizacionUrl,
        );
        if (res.status === 201) {
          setFileTwoLoading(false);
          setUploadSuccessTwo(true);
        }
      }
    } catch (e) {
      setError(true);
    }
  };

  React.useEffect(() => {
    if ((!needsSettlement || (filesOne.length > 0
      && filesOne.every((file) => file.fileOk)))
      && (!needsPensionStatement || fileTwoOk)
    ) {
      handleDocumentsOk(true);
      if ((!needsSettlement || (filesOne.every((file) => file.uploadSuccess)))
        && (!needsPensionStatement || uploadSuccessTwo)) {
        handleUploadOk();
      }
    } else {
      handleDocumentsOk(false);
    }
  }, [filesOne.length > 0 ? filesOne.every((file) => file.fileOk) : undefined,
    filesOne.length > 0 ? filesOne.every((file) => file.uploadSuccess) : undefined,
    fileTwoOk,
    uploadSuccessTwo]);

  React.useEffect(() => {
    if (triggerUpload) {
      getURLandUpload();
    }
  }, [triggerUpload]);

  return (
    <>
      <div className={(needsSettlement && needsPensionStatement)
        ? classes.twoButtonsDisplay
        : classes.oneButtonDisplay}
      >
        {needsSettlement
          && (
            <MultipleDocumentsUploadDialog
              documentsQuantity={settlementsRequired || 1}
              files={filesOne}
              handleSetFiles={handleSetFilesOne}
              loading={filesOneUploading}
            />
          )}
        {needsPensionStatement
          && (
            <DocumentUploadButton
              label={(
                <div style={{ whiteSpace: 'pre-wrap' }}>
                  Subir últimas
                  {' '}
                  {pensionStatementsRequired || <span>12</span>}
                  {' '}
                  <span>
                    cotizaciones de AFP
                  </span>
                </div>
)}
              file={fileTwo ? fileTwo[0] : undefined}
              type="cotizaciones"
              handleSetFiles={handleSetFileTwo}
              fileOk={handlePensionStatementFileOk}
              documentUploading={fileTwoUploading}
              uploadSuccess={uploadSuccessTwo}
              handleSetFileInputRefs={handleSetFileInputRefsTwo}
              maxPages={3}
            />
          )}
      </div>
      {error
        && (
          <div style={{ textAlign: 'center' }}>
            <Typography
              variant="caption"
              color="error"
              maxWidth={400}
              component="div"
              align="center"
              margin="auto"
              marginTop={2}
            >
              Ha ocurrido un error cargando tus archivos,
              por favor escríbenos para atender tu caso
            </Typography>
          </div>
        )}
    </>
  );
}

UploadDocuments.defaultProps = defaultProps;
