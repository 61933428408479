import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  stepper: {
    margin: 'auto',
    padding: 0,
    maxWidth: theme.spacing(100),
    backgroundColor: theme.palette.background.defaultSecondary,
  },
  paper: {
    height: 545,
    margin: theme.spacing(3, 'auto'),
    textAlign: 'center',
    maxWidth: '480px',
    [theme.breakpoints.down('md')]: {
      padding: '2% 10px',
      margin: theme.spacing(3, '1.5%'),
    },
  },
  submitButton: {
    maxWidth: theme.spacing(80),
    margin: theme.spacing(4, 'auto'),
    marginBottom: 0,
  },
  backButton: {
    margin: 'auto',
  },
  isotipo: {
    marginBottom: 5,
    marginTop: 5,
  },
  logo: {
    marginBottom: 5,
    marginTop: 5,
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },

}));

export default useStyles;
