import {
  Box,
  Button,
  Divider,
  Fade, Paper, Step,
  StepLabel, Stepper, Typography,
} from '@mui/material';
import React from 'react';
import * as Sentry from '@sentry/react';
import { QontoConnector } from '../../QontoStepIcon/components';
import QontoStepIcon from '../../QontoStepIcon';
import useStyles from './styles';
import getPartnerButton from '../../PartnerButtons';
import { Origin, Partner } from '../types';
import ErrorFallback, { errorHandler } from '../../ErrorFallback';
import isotipoDark from '../../../assets/isotipo_dark_secondary.svg';
import { steps } from '../../../feature/preloan/v3/PreLoanForm/formLogic';

type LeadFormProps ={
  title: string,
  stepperSteps: number;
  origin: Origin,
  partner: Partner,
  submitButtonProps:{
    disabledSteps: number[],
    getButtonLabel: (step: number, partnerName: string) => string,
    testingLabel: 'preloan-submit-button'| 'signature-submit-button',
  },
  backButtonProps: {
    getButtonLabel: (step: number, partnerName: string) => string,
    disabledSteps: number[],
  },
  getStepContent: (params: {
    activeStep: number,
    handleNext: () => void,
    handleStep: (step: number) => void,
    handleEnableLoading: (state: boolean) => void,
  }) => React.ReactElement<any, any>,
  customBackSteps?: {activeStep: number, customBackStep: number}[],
  onSubmit: () => void,
  onErrorReset: () => void,
}
const defaultProps = {
  customBackSteps: [],
};

export default function LeadForm(props: LeadFormProps) {
  const {
    title,
    stepperSteps,
    partner,
    submitButtonProps,
    backButtonProps,
    getStepContent,
    customBackSteps = [],
    onSubmit,
    onErrorReset,
  } = props;

  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => setActiveStep((prevStep) => prevStep + 1);
  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);

  const handleStep = (step: number) => setActiveStep(step);

  const [enableLoading, setEnableLoading] = React.useState(false);

  const handleEnableLoading = (state: boolean) => setEnableLoading(state);

  const handleBackSteps = (step: number) => {
    if (customBackSteps.length > 0) {
      const customBackStep = customBackSteps.find((item) => item.activeStep === step);
      if (customBackStep) {
        return handleStep(customBackStep.customBackStep);
      }
    }
    return handleBack();
  };
  return (
    <Sentry.ErrorBoundary
      fallback={<ErrorFallback />}
      onError={
          (error, componentStack) => {
            errorHandler(
              error as Error,
              { componentStack: componentStack as string },
              undefined,
              undefined,
            );
          }
      }
      onReset={onErrorReset}
    >
      <Fade
        in
        {...({ timeout: 1000 })}
      >
        <div style={{ width: '100%' }}>
          <br />
          <Typography
            className={classes.title}
            fontWeight="bold"
            variant="h6"
            component="div"
            align="center"
            gutterBottom
          >
            {title}
          </Typography>
          <Stepper
            className={classes.stepper}
            alternativeLabel
            activeStep={activeStep}
            connector={<QontoConnector />}
          >
            {[...Array(stepperSteps).keys()].map((num) => (
              <Step key={num}>
                <StepLabel StepIconComponent={QontoStepIcon} />
              </Step>
            ))}
          </Stepper>
          <Paper
            className={classes.paper}
            elevation={0}
            style={{ borderRadius: '10px', marginInline: 'auto' }}
          >
            <div style={{
              borderRadius: '10px 10px 0px 0px',
              paddingTop: 5,
            }}
            >
              <div className={classes.isotipo}>
                <object
                  data={isotipoDark}
                  aria-labelledby="Isotipo"
                  height="25px"
                />
              </div>
            </div>
            <Divider />
            <Box
              sx={{ padding: { sx: '2.5% 5px', md: '2.5% 25px' } }}
            >
              <Typography
                variant="body1"
                align="center"
                gutterBottom
                fontWeight="bold"
                paddingTop={1}
              >
                {steps[activeStep]}
              </Typography>
              {getStepContent({
                activeStep,
                handleNext,
                handleStep,
                handleEnableLoading,
              })}
            </Box>
          </Paper>
          {getPartnerButton({
            partner,
            className: classes.submitButton,
            testingLabel: submitButtonProps.testingLabel,
            step: activeStep,
            buttonLabel: submitButtonProps.getButtonLabel(activeStep, partner.partnerName),
            onClick: onSubmit,
            disabled: enableLoading
          || submitButtonProps.disabledSteps.includes(activeStep),
          })}
          <div>
            <Button
              className={classes.backButton}
              disabled={backButtonProps.disabledSteps.includes(activeStep) || enableLoading}
              color="primary"
              onClick={() => handleBackSteps(activeStep)}
            >
              Volver
            </Button>
          </div>
        </div>
      </Fade>
    </Sentry.ErrorBoundary>
  );
}

LeadForm.defaultProps = defaultProps;
