import React from 'react';
import {
  Typography, Link, FormControlLabel, Checkbox, useTheme,
} from '@mui/material';
import useStyles from './styles';
import analytics from '../../utils/analytics';

type TermsAndConditionsLinkType = {
  origin: string,
  checked: boolean,
  handleSetChecked: () => void,
}

export default function TermsAndConditionsLink(props: TermsAndConditionsLinkType) {
  const classes = useStyles();
  const theme = useTheme();
  const { origin, checked, handleSetChecked } = props;
  const handleCheckBox = () => {
    analytics.track('Click Checkbox', {
      text: 'Acepto los TyC y política privacidad',
      initiator: 'usuario',
    });
    handleSetChecked();
  };
  const handleClickTermsAndConditions = () => {
    analytics.track('Click Link', {
      text: 'términos y condiciones',
      initiator: 'usuario',
    });
  };
  const handleClickPrivacyPolicy = () => {
    analytics.track('Click Link', {
      text: 'política de privacidad',
      initiator: 'usuario',
    });
  };
  return (
    <FormControlLabel
      style={{
        backgroundColor: theme.palette.background.defaultSecondary,
        padding: 10,
        borderRadius: 20,
      }}
      control={(
        <Checkbox
          size="small"
          checked={checked}
          onClick={handleCheckBox}
          data-pw="preloan-default-optIn"
        />
          )}
      sx={{ marginLeft: '1em', marginRight: '1em', maxWidth: 650 }}
      label={(
        <Typography className={classes.termsAndConditions} align="left" variant="body2">
          Acepto los
          {' '}
          <Link
            onClick={handleClickTermsAndConditions}
            style={{ textDecoration: 'underline' }}
            href={`/terms-and-conditions?origin=${origin}&referrer=plr`}
            target="_blank"
            rel="noopener"
          >
            términos y condiciones
          </Link>
          {' '}
          y
          {' '}
          <Link
            onClick={handleClickPrivacyPolicy}
            style={{ textDecoration: 'underline' }}
            href="https://www.banca.me/politicas-de-privacidad"
            target="_blank"
            rel="noopener"
          >
            política de privacidad
          </Link>
          , lo que incluye la consulta de cuentas y deudas asociadas a mi RUT
        </Typography>
          )}
    />

  );
}
