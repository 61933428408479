import React from 'react';
import { Typography } from '@mui/material';
import * as Sentry from '@sentry/react';
import isotipoDark from '../../assets/isotipo_dark_secondary.svg';
import AgentContactButton from '../AgentContactButton';

declare const window: any;

export const errorHandler = (
  error: Error,
  info: { componentStack: string },
  clientToken: string | undefined,
  user: any | undefined,
) => {
  window.newrelic.noticeError(
    error,
    {
      componentStack: info.componentStack,
      url: window.location.href,
      clientToken,
      user,
    },
  );
};

export default function ErrorFallback() {
  const [eventId, setEventId] = React.useState<string|undefined>('');
  Sentry.getCurrentScope().getClient()?.on('afterSendEvent', (event) => {
    if (!event.type && event.event_id) { // only show the dialog for error events
      setEventId(event.event_id);
    }
  });
  return (
    <div style={{
      display: 'flex', height: '100vh', justifyContent: 'center', textAlign: 'center', alignItems: 'center',
    }}
    >
      <div role="alert">
        <img src={isotipoDark} width="60px" alt="isotipo banca.me" />
        <Typography
          variant="h6"
          style={{ fontWeight: 'bold', marginTop: 10 }}
          gutterBottom
        >
          ¡Ha ocurrido un error inesperado!

        </Typography>
        <Typography variant="h6" style={{ margin: '10px 10%' }} gutterBottom>
          Estamos trabajando para poder resolver este problema lo antes posible.
        </Typography>
        <Typography variant="h6" style={{ margin: '10px 10%' }} gutterBottom>
          Por favor vuelve al inicio e inténtalo de nuevo.
        </Typography>
        <Typography
          variant="h6"
          style={{
            padding: '10px',
            backgroundColor: 'whitesmoke',
          }}
          gutterBottom
        >
          ID EVENTO:
          {' '}
          <b>
            {eventId}
          </b>
        </Typography>
        <AgentContactButton reportError eventId={eventId} />
      </div>
    </div>
  );
}
