import React from 'react';
import {
  Grid, Paper, Typography, Button, useTheme,
  ListItem,
  ListItemText,
} from '@mui/material';
import useStyles from './styles';
import type { ProductsData } from '../../../types/products';
import BuyProductDialog from '../BuyProductDialog';
import BackButton from '../../../components/BackButton';
import analytics from '../../../utils/analytics';
import bancameLogo from '../../../assets/bancame_logo_white.svg';
import isotipoDark from '../../../assets/isotipo_dark_secondary.svg';

const productTypeLabels: {[key:string]:{label: string,
  icon: null|React.ReactElement, description: string}} = {
    'user-report': {
      label: 'Obtenlo por solo',
      icon: <object data={isotipoDark} aria-label="Isotipo dark" width="20px" />,
      description: '¡El precio más conveniente del mercado!',
    },
  };

const getProductTypeLabels = (type: string) => {
  if (Object.keys(productTypeLabels).includes(type)) {
    return productTypeLabels[type];
  }
  return { label: type, icon: null, description: null };
};

type ProductsOverviewProps = {
    token: string;
    productsData: ProductsData['data'];
}

export default function ProductsOverview(props:ProductsOverviewProps) {
  const { token, productsData } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [product] = React.useState(productsData[0]);

  const backToAccount = () => {
    window.location.href = '/account/products';
  };
  const goToUserReportDemo = () => {
    window.location.href = '/account/products/user-report/demo';
  };

  React.useEffect(() => {
    analytics.page('PRODUCTOS', 'HP PRODUCTOS');
  }, []);

  return (
    <div
      className={classes.contentContainer}
    >
      {token
      && (
      <BackButton
        lightMode
        label="Volver a Guia.me"
        onClickAction={backToAccount}
      />
      )}
      <Grid
        container
        columnGap={10}
        className={classes.gridContainer}
      >
        <Grid
          item
          xs={false}
          md={6}
          sx={{ margin: 4, marginTop: { xs: 0, sm: 4 } }}
        >

          <div className={classes.title}>
            <object
              data={bancameLogo}
              width="150px"
              aria-labelledby="banca.me logo"
              style={{ marginRight: 10 }}
            />
            <Typography
              color="white.main"
              variant="h5"
              component="div"
              sx={{ paddingTop: { xs: '5px', md: '3px' } }}

            >
              presenta
            </Typography>
          </div>

          <br />
          <Typography
            variant="button"
            fontWeight="bold"
            style={{
              padding: 2,
              background: theme.palette.tertiary.main,
              color: theme.palette.black.main,
            }}
          >
            Nuevo
          </Typography>
          <Typography
            variant="h2"
            color="white.main"
            sx={{ marginBottom: { xs: 3, sm: 7 }, fontWeight: { xs: 'bold', md: 'regular' } }}
            marginTop={1}
          >
            Informe de deudas
          </Typography>
          <Typography
            color="white.main"
            component="div"
            sx={{ fontSize: { xs: '1.2rem', sm: '2rem' } }}
          >
            Descubre tus deudas y toma control de tus finanzas
          </Typography>
          <ListItem
            component="div"
            disablePadding
            sx={{ display: 'list-item', marginLeft: '16px', color: theme.palette.white.main }}
          >
            <ListItemText
              primaryTypographyProps={{ fontSize: '1.3rem', color: theme.palette.white.main }}
              primary={(
                <>
                  Detalle consolidado de tus
                  {' '}
                  <b style={{ color: theme.palette.tertiary.main }}>
                    {' '}
                    deudas actuales y morosas
                  </b>
                  {', '}
                  incluyendo bancos, cooperativas, cajas de compensación, y casas comerciales
                </>
              )}
            />
          </ListItem>
          <ListItem
            component="div"
            disablePadding
            sx={{ display: 'list-item', marginLeft: '16px', color: theme.palette.white.main }}
          >
            <ListItemText
              primaryTypographyProps={{ fontSize: '1.3rem', color: theme.palette.white.main }}
              primary={(
                <>
                  Conoce cómo te ven los bancos con tu
                  {' '}
                  <b style={{
                    color: theme.palette.tertiary.main,
                  }}
                  >
                    puntaje crediticio
                  </b>
                </>
)}
            />
          </ListItem>

        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          component={Paper}
          elevation={5}
          className={classes.gridItem}
          style={{ borderRadius: 20 }}
        >
          <div style={{ maxWidth: 350, margin: '20px' }}>
            {getProductTypeLabels(product.type).icon}
            <Typography
              variant="h4"
              gutterBottom
            >
              {getProductTypeLabels(product.type).label}
            </Typography>
            <Typography
              variant="h3"
              gutterBottom
              style={{ color: theme.palette.secondary.main }}
            >
              {`$ ${product.amount.toLocaleString('es-CL')}`}
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              color="primary"
            >
              {getProductTypeLabels(product.type).description}
            </Typography>
            <div className={classes.buttonsContainer}>
              <Button
                variant="outlined"
                color="primary"
                style={{ width: 150 }}
                onClick={goToUserReportDemo}
              >
                Ver Ejemplo

              </Button>
              <BuyProductDialog
                token={token}
                productType={product.type}
                productId={product.id}
                amount={product.amount}
                redirectURL="/account/products"
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
