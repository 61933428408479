import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  viewTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 4),
    [theme.breakpoints.down('md')]: {
      margin: 100,
    },
  },
}));

export default useStyles;
