import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import BackgroundPic from /* webpackPreload: true */ '../../assets/background_isotipo_dark.svg';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100vh',
    position: 'absolute',
    textAlign: 'center',
  },
  leftGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      alignItems: 'center ',
    },
  },

  rightGrid: {
    backgroundColor: theme.palette.background.darkSecondary,
    backgroundImage: `url(${BackgroundPic})`,
    backgroundRepeat: 'round',
    backgroundPosition: 'right 150px',
    backgroundSize: '10%',
    transform: 'rotateX(180deg)',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  rightGridPartner: {
    backgroundColor: theme.palette.background.default,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'contain',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    flexDirection: 'column',
  },
}));

export default useStyles;
