import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Tab, Tabs, Typography, useTheme,
} from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import StarsRoundedIcon from '@mui/icons-material/StarsRounded';
import BarChartIcon from '@mui/icons-material/BarChart';
import SummarizeIcon from '@mui/icons-material/Summarize';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { KeyboardArrowDown } from '@mui/icons-material';
import analytics from '../../utils/analytics';
import AlertDialog from '../AlertDialog';

type StyledTabsProps = {
    children?: React.ReactNode;
    value: number|false;
    onChange: (event: React.SyntheticEvent, newValue: number) => void;
  }
type StyledTabProps = {
  label: string;
}
export const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
      // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
))(({ theme }) => ({
  '& .MuiButtonBase-root.Mui-selected': {
    color: theme.palette.secondary.main,
  },
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '&:hover': {
    color: theme.palette.secondary.main,
  },
}));

export const StyledTab = styled((props: StyledTabProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Tab disableRipple {...props} icon={<KeyboardArrowDown />} iconPosition="end" />
))(({ theme }) => ({
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightBold,
  fontSize: theme.typography.pxToRem(16),
  marginLeft: theme.spacing(1),
  color: theme.palette.black.main,
  paddingBottom: 0,
  transition: '0.5s',
  '&:hover': {
    color: 'inherit',

  },
  '& .MuiTab-iconWrapper': {
    transition: '1s',
    '&:hover': {
      paddingBottom: 5,
    },
  },
}));

export const mobileIcons = (tabValue:string):{[key:string]:React.ReactElement} => {
  const theme = useTheme();
  const buttonStyle = (value:string) => (
    {
      color: tabValue === value ? theme.palette.secondary.main : undefined,
      paddingBottom: '6px',
      fontSize: '22px',
    }
  );
  return (
    {
      'Tu crédito': <CreditCardIcon
        style={buttonStyle('Tu crédito')}
      />,
      Referidos: <StarsRoundedIcon
        style={buttonStyle('Referidos')}
      />,
      Inversiones: <BarChartIcon
        style={buttonStyle('Inversiones')}
      />,
      Pagos: <AccountBalanceIcon
        style={buttonStyle('Pagos')}
      />,
      'Guia.me': <SummarizeIcon
        style={buttonStyle('Guia.me')}
      />,
    });
};

const creditos = { label: 'Tu crédito', url: '/account', mobileIcon: CreditCardIcon };
const referidos = { label: 'Referidos', url: '/account/referrals', mobileIcon: StarsRoundedIcon };
const inversiones = { label: 'Inversiones', url: '/account/investments', mobileIcon: BarChartIcon };
const guiame = { label: 'Guia.me', url: '/account/products', mobileIcon: SummarizeIcon };
const pagos = { label: 'Pagos', url: '/account/pac', mobileIcon: AccountBalanceIcon };

export const totalTabs = [creditos, referidos, inversiones, guiame, pagos];
export const onlyInvestTabs = [inversiones, guiame, pagos];
export const onlyLoanTabs = [creditos, referidos, guiame, pagos];
export const onlyProductsTab = [guiame, pagos];

export function logOut() {
  analytics.track('Click Boton', { text: 'Continuar', initiator: 'usuario' });
  analytics.reset();
  sessionStorage.removeItem('__react_session__');
  window.location.href = '/account/login';
}

export function CloseAlertDialog(props: { button: React.ReactElement }) {
  const { button } = props;
  return (
    <AlertDialog
      dialogTitle={(
        <Typography
          fontWeight="bold"
          variant="h6"
          component="div"
          align="center"
        >
          ¿Cerrar sesión?
        </Typography>
      )}
      dialogContent="Si presiona continuar, se redirigirá a la vista de inicio de sesión"
      action={() => logOut()}
      button={button}
      pageCall={{ category: 'PU - LOGOUT', name: 'CONFIRMAR LOGOUT' }}
    />
  );
}
