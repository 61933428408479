import React from 'react';
import {
  Divider,
  Fade,
  Grid,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import useStyles from './styles';
import { productTypeLabels } from '../../../components/CreditOverview';

type PrepayRequestSimulationProps = {
    totalPrepaySimulation: {
      unpaidCapital: number,
      paidAmount: number,
      delinquencyDebt: number,
      interestAmount: number,
      prepayComission: number,
      totalToDeposit: number
  },
  partner: string,
  productType: string,
  paymentMethod: string
}

export default function PrepayRequestSimulation(props: PrepayRequestSimulationProps) {
  const {
    totalPrepaySimulation, partner, productType, paymentMethod,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Fade
      in
      {...({ timeout: 2500 })}
    >
      <Grid
        container
        justifyContent="flex-end"
        className={classes.infoContainer}
        columnGap={5}
        rowGap={5}
      >
        <Grid
          item
          xs={12}
          alignSelf="center"
          textAlign="center"
        >

          <Typography
            display="inline"
            variant="h4"
            fontWeight="bold"
          >
            {productTypeLabels[productType]}
          </Typography>
          <Typography gutterBottom>
            {partner}
            {' '}
            [
            {paymentMethod.toUpperCase()}
            ]
            {' '}

          </Typography>
          <Divider />
        </Grid>
        <Grid
          item
          xs={12}
          lg={3}
          component={Paper}
          borderRadius={3}
          alignContent="center"
          textAlign="center"
          elevation={0}
          padding={5}
          alignSelf="center"
          height={130}
        >
          <Typography
            variant="h4"
            style={{ color: theme.palette.primary.main }}
            fontWeight="bold"
            gutterBottom
          >
            $
            {totalPrepaySimulation.totalToDeposit.toLocaleString('es-CL')}
          </Typography>
          <Typography
            variant="h6"
          >
            Monto a cancelar para realizar prepago

          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          lg={3}
          component={Paper}
          borderRadius={3}
          elevation={0}
          alignContent="center"
          alignSelf="center"
          padding={5}
          height={130}
        >
          <Typography
            variant="h6"
            fontWeight="bold"
            gutterBottom
          >
            Detalles

          </Typography>
          <Typography
            variant="body2"
          >
            Capital a cancelar: $
            <b>{totalPrepaySimulation.unpaidCapital.toLocaleString('es-CL')}</b>
          </Typography>
          <Typography
            variant="body2"
          >
            Comisión prepago: $
            <b>{totalPrepaySimulation.prepayComission.toLocaleString('es-CL')}</b>
          </Typography>
          <Typography
            variant="body2"
          >
            Intereses devengados: $
            <b>{totalPrepaySimulation.interestAmount.toLocaleString('es-CL')}</b>
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          lg={5}
          className={classes.prepaySavingsContainer}
          alignSelf="center"
          padding={5}
          alignContent="center"
          textAlign="center"
          height={130}
        >
          <Typography
            variant="h6"
            fontWeight="bold"
            align="center"
            color="white.main"
          >
            Te estás ahorrando

          </Typography>

          <Typography
            variant="h3"
            fontWeight="bold"
            color="white.main"
            align="center"
          >
            $
            {(totalPrepaySimulation.unpaidCapital - totalPrepaySimulation.totalToDeposit).toLocaleString('es-CL')}

          </Typography>

        </Grid>
      </Grid>
    </Fade>
  );
}
