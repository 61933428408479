/* eslint-disable no-console */
import React from 'react';
import './App.css';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import * as Sentry from '@sentry/react';
import { initMercadoPago } from '@mercadopago/sdk-react';
import { IntercomProvider } from 'react-use-intercom';
import { Helmet } from 'react-helmet';
import AppRoutes from './navigation';
import { theme } from './theme';

const setSentryEnv = (url: string) => {
  if (url.includes('dev')) {
    return 'development';
  }
  if (url.includes('beta')) {
    return 'beta';
  }
  return 'production';
};
if (!window.location.host.includes('localhost')) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        networkDetailAllowUrls: [`${process.env.REACT_APP_API_URL}/`],
        networkRequestHeaders: ['X-Custom-Header'],
        networkResponseHeaders: ['X-Custom-Header'],
      })],
    environment: setSentryEnv(window.location.host),
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  });
}

function App() {
  initMercadoPago(process.env.REACT_APP_MELI_PAYMENTS_PUBLIC_KEY as string, { locale: 'es-CL' });

  return (
    <>
      <Helmet>
        <link
          rel="preload"
          href="/fonts/Inter/Inter-Regular.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/fonts/Inter/Inter-Bold.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/fonts/Inter/Inter-Light.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/assets/bancame_logo_dark.svg"
          as="image"
          type="image/svg+xml"
        />
        <link
          rel="preload"
          href="/assets/bancame_logo_white.svg"
          as="image"
          type="image/svg+xml"
        />
        <link
          rel="preload"
          href="/assets/isotipo_dark_secondary.svg"
          as="image"
          type="image/svg+xml"
        />
        <link
          rel="preload"
          href="/assets/uaf.svg"
          as="image"
          type="image/svg+xml"
        />
        <link
          rel="preload"
          href="/assets/logo-experian.svg"
          as="image"
          type="image/svg+xml"
        />
      </Helmet>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <IntercomProvider appId={process.env.REACT_APP_INTERCOM_APP_ID as string}>
          <AppRoutes />
        </IntercomProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
