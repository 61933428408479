import React from 'react';
import {
  Card,
  Grid,
  Fade,
  Typography,
  Link,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import useStyles from './styles';
import ReferralCodeDialog from '../../../components/ReferralUrlDialog/index';
import ViewTitle from '../../../components/ViewTitle/index';
import CustomPieChart from '../../../components/Charts/CustomPieChart/index';
import { ReferralsData } from '../../../types/account';
import AcceptOptInDialog from './acceptOptInDialog';
import analytics from '../../../utils/analytics';

type ReferralMenuProps = {
    referralsData: ReferralsData['data'],
    token: string
}

function ReferralMenu(props: ReferralMenuProps) {
  const { referralsData, token } = props;
  const classes = useStyles();

  const [piechartData, setPiechartData] = React.useState<
    { id: string, name: string, value: number }[]
  >([]);

  React.useEffect(
    () => {
      if (referralsData.conversion) {
        const pieData = [];
        if (+referralsData.conversion.preLoanRequests > 0) {
          pieData.push({
            id: 'PLR',
            name: 'Clicks URL',
            value: +referralsData.conversion.preLoanRequests,
          });
        }
        if (+referralsData.conversion.loanRequests > 0) {
          pieData.push({
            id: 'LR',
            name: 'Solicitudes incompletas',
            value: +referralsData.conversion.loanRequests,
          });
        }
        if (+referralsData.conversion.loans > 0) {
          pieData.push({
            id: 'L',
            name: 'Créditos aprobados',
            value: +referralsData.conversion.loans,
          });
        }
        setPiechartData(pieData);
      }
    },
    [],
  );

  React.useEffect(() => {
    if (referralsData.referralCode) {
      analytics.page('PU - REFERIDOS', 'MIS REFERIDOS');
    } else {
      analytics.page('PU - REFERIDOS', 'ACEPTAR TyC REFERIDOS');
    }
  }, [referralsData.referralCode]);

  return (
    <Grid
      container
      justifyContent="flex-end"
      rowGap={5}
    >
      <ViewTitle
        title="Mis Referidos"
        subtitle="En esta sección podrás hacerle seguimiento a los referidos que han usado tu enlace único."
        color="primary"
        variant="h3"
      />
      <Fade in {... ({ timeout: 2500 })}>
        <Grid
          item
          xs={12}
          lg={11}
        >
          {referralsData.referralCode ? (
            <>
              <div className={classes.cardsContainer}>
                <Card className={classes.infoCard} sx={{ borderRadius: '15px' }}>
                  <div>
                    <Typography display="inline" variant="h6">
                      <b>Monto pagado </b>
                      por referidos existosos
                    </Typography>
                  </div>
                  <div style={{
                    display: 'flex', alignItems: 'center', placeContent: 'left',
                  }}
                  />
                  <Typography variant="h4" fontWeight="bold" color="secondary">
                    $
                    {referralsData.accumulatedClaimedPrize.toLocaleString('es-CL')}
                  </Typography>

                </Card>
                <Card className={classes.infoCard} sx={{ borderRadius: '15px' }}>
                  <div>
                    <Typography display="inline" variant="h6">
                      <b>Monto total </b>
                      por referidos existosos
                    </Typography>
                  </div>
                  <div style={{
                    display: 'flex', alignItems: 'center', placeContent: 'left',
                  }}
                  />
                  <Typography variant="h4" fontWeight="bold" color="secondary">
                    $
                    {referralsData.accumulatedPrize.toLocaleString('es-CL')}
                  </Typography>

                </Card>
                <Card className={classes.infoCard} sx={{ borderRadius: '15px' }}>
                  <div>
                    <Typography display="inline" variant="h6">
                      Referidos
                      <b> logrados </b>
                      v/s referidos
                      <b> máximos</b>
                    </Typography>
                  </div>
                  <div />
                  <Typography variant="h4" fontWeight="bold" color="secondary">
                    {referralsData.numberOfPrizes.toLocaleString('es-CL')}
                    {' / '}
                    {referralsData.maxPrizes.toLocaleString('es-CL')}
                  </Typography>

                </Card>
              </div>
              <Card
                sx={{ borderRadius: '15px' }}
                className={classes.referralOverview}
              >
                <div style={{ margin: '10px' }}>
                  <Typography
                    variant="h5"
                    gutterBottom
                  >
                    Un total de
                    <b>
                      {` ${referralsData.conversion.preLoanRequests} `}
                      personas han usado tu enlace.
                      {' '}
                    </b>
                  </Typography>
                  <Typography
                    variant="h5"
                  >
                    De ellas,
                    <b>
                      {` ${referralsData.conversion.loans} `}
                      recibieron un crédito.
                    </b>
                  </Typography>
                </div>
                {piechartData.length > 0
                  ? (
                    <CustomPieChart
                      data={piechartData}
                      isMoney={false}
                      showPercentage={false}
                    />
                  )
                  : (
                    <div style={{ textAlign: 'center', marginBlock: '5%' }}>
                      <Typography
                        align="center"
                        color="secondary"
                        fontWeight="bold"
                        style={{ maxWidth: 300, margin: 'auto' }}
                      >
                        Aquí aparecerán las estadísticas de tus
                        referidos cuando utilicen tu enlace
                      </Typography>
                    </div>
                  )}
              </Card>

              <div className={classes.actionButtons}>
                <ReferralCodeDialog
                  referralCode={referralsData.referralCode}
                />
              </div>
            </>
          ) : (

            <Card
              sx={{ borderRadius: '15px' }}
              className={classes.referralOverview}
            >
              <div>
                <Typography
                  variant="h5"
                  gutterBottom
                >
                  Aún no aceptas los terminos y condiciones para participar del
                  {' '}
                  <b>
                    Programa de Referidos
                  </b>
                </Typography>
              </div>
              <div style={{ textAlign: 'center', marginBlock: '5%' }}>
                <AcceptOptInDialog token={token} />
              </div>
            </Card>

          )}
          <div className={classes.footerInfo}>
            <div className={classes.footerIcon}>
              <InfoOutlinedIcon style={{ fontSize: '20px', paddingBottom: 10 }} />
            </div>
            <Typography
              display="inline"
              variant="body2"
              fontWeight="light"
              className={classes.footerText}
            >
              ¡Si tu referido utiliza tu
              enlace para solicitar un crédito y lo recibe, tú también ganas!
            </Typography>
          </div>
          <div className={classes.footerInfoTermsAndConditions}>
            <Link style={{ textDecoration: 'underline' }} href="/referral-terms-and-conditions" target="_blank" rel="noopener">
              <b>Términos y Condiciones</b>
            </Link>
          </div>
        </Grid>
      </Fade>
    </Grid>
  );
}

export default ReferralMenu;
