import React from 'react';
import Simulator from '../../../../../../../components/Simulator';
import type { FormSchemaV3 } from '../../../../../../../types/preloan/v3';
import { AmountMarksType } from '../../../../../../../components/Simulator/types';

type LoanSimulatorProps ={
    showSimulator: boolean,
    formSchema: FormSchemaV3,
    requestLoan: (params:{
        amount: number,
        interestRate: number,
        periods: number,
        installment: number,
        totalCost: number,
        monthlyCAE: number,
        CAE: number,
        originCost: number,
        taxes: number,
        insuranceCost: number,
        isInitialState: boolean
    }) => void,
      handleLoadingSimulation: (isLoading:boolean)=> void,
      archivePLR: ()=>void,
      amountMarks: AmountMarksType|undefined,
}

export default function LoanSimulator(props:LoanSimulatorProps) {
  const {
    showSimulator,
    formSchema,
    requestLoan,
    handleLoadingSimulation,
    archivePLR,
    amountMarks,
  } = props;
  return (
    <div style={{
      opacity: showSimulator ? 1 : 0,
      height: showSimulator ? undefined : 0,
      margin: 'auto',
    }}
    >
      <Simulator
        loanParams={formSchema.loanParams}
        requestLoan={requestLoan}
        isPLR
        fixAmount={+formSchema.requestedAmount}
        fixPeriod={+formSchema.requestedPeriods}
        reset={!showSimulator}
        disabledSliders={!showSimulator}
        handleLoadingSimulation={handleLoadingSimulation}
        handleRejection={archivePLR}
        offerMaxAmount={false}
        origin={formSchema.origin}
        amountMarks={amountMarks}
      />
    </div>
  );
}
