import React from 'react';
import {
  Grid, useTheme, Toolbar, Button,
} from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { ReactSession } from 'react-client-session';
import { KeyboardArrowDown } from '@mui/icons-material';
import bancameLogo from '../../assets/bancame_logo_dark.svg';
import useStyles from './styles';
import { useIsWidthDown } from '../../utils/widthHooks';
import jwtDecode from '../../utils/jwtDecode';
import {
  totalTabs,
  onlyInvestTabs,
  onlyLoanTabs,
  onlyProductsTab,
  CloseAlertDialog,
} from './helpers';
import { getElementFromStorage } from '../../utils/helpers';
import MobileNavigation from './MobileNavigation';

export type AccountNavbarProps = {
  children: React.ReactElement<any, any>
}

export default function AccountNavbar(props:AccountNavbarProps) {
  const {
    children,
  } = props;
  const token = getElementFromStorage('token');
  const { hasLoan, hasInvestment } = jwtDecode(token);
  const {
    userId, idNumber, email, firstName, lastName,
  } = jwtDecode(ReactSession.get('token'));

  const location = useLocation();

  const [showButtons, setShowButtons] = React.useState(false);

  React.useEffect(() => {
    setShowButtons(!!token);
  }, [token]);

  const { boot: bootIntercom, shutdown: shutdownIntercom } = useIntercom();
  bootIntercom({
    name: `${firstName} ${lastName}`,
    email,
    userId,
    customAttributes: { idNumber },
  });

  function getShowableTabs() {
    if (hasInvestment && !hasLoan) {
      return onlyInvestTabs;
    }
    if (!hasInvestment && hasLoan) {
      return onlyLoanTabs;
    }
    if (!hasInvestment && !hasLoan) {
      return onlyProductsTab;
    }
    return totalTabs;
  }
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const isMdDown = useIsWidthDown('md');
  const showableTabs = getShowableTabs();
  const mobileTabs = [...showableTabs].reverse();

  React.useEffect(() => {
    if (!token) {
      shutdownIntercom();
    }
  }, [token]);

  return (
    <>
      <Grid
        xs={12}
        item
        container
        className={classes.navBar}
        component={Toolbar}
      >
        <Grid item xs={2}>
          <img
            src={bancameLogo}
            className={classes.logo}
            alt="Logo banca.me"
          />
        </Grid>
        <Grid item xs={6} />
        <Grid
          xs={4}
          md={4}
          item
          justifySelf="right"
          justifyContent="right"
          justifyItems="right"
        >
          {(showButtons)
        && (
          <div style={{
            display: 'grid',
            gridTemplateColumns: !isMdDown ? `repeat(${showableTabs.length + 1},1fr)` : '1fr',
            alignItems: 'right',
            justifyItems: 'right',
          }}
          >
            {showableTabs.filter(() => !isMdDown)
              .map((t) => (
                <div
                  className={classes.navButton}
                  onClick={() => history.push(t.url)}
                  aria-hidden
                  style={{
                    color: location.pathname === t.url
                      ? theme.palette.secondary.main : undefined,
                  }}
                >
                  {t.label}
                  <KeyboardArrowDown
                    style={{
                      color: location.pathname === t.url
                        ? theme.palette.secondary.main : undefined,
                    }}
                    className={classes.arrowIcon}
                  />
                </div>
              ))}
              {!isMdDown
              && (
                <CloseAlertDialog button={(
                  <Button
                    style={{ maxWidth: 100 }}
                    variant="outlined"
                  >
                    Salir
                  </Button>
)}
                />
              )}
            <div />
          </div>
        )}
        </Grid>
      </Grid>
      {isMdDown
      && showButtons
      && <MobileNavigation showableTabs={mobileTabs} />}
      <div className={classes.children}>
        {children}
        {isMdDown && showButtons && <div style={{ height: 50 }} />}
      </div>

    </>
  );
}
