import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import BackgroundPic from '../../../assets/background_forms_light_secondary.svg';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    position: 'absolute',
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    backgroundImage: `url(${BackgroundPic})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    [theme.breakpoints.down('sm')]: {
      height: '102vh',
    },
  },
  stepper: {
    margin: 'auto',
    padding: 0,
    maxWidth: theme.spacing(100),
    backgroundColor: theme.palette.white.main,
  },
  paper: {
    margin: theme.spacing(3, 15),
    padding: '30px',
    textAlign: 'center',
    minHeight: '58vh',
    maxHeight: '90%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.white.main,
    [theme.breakpoints.down('md')]: {
      padding: '5% 25px',
      margin: theme.spacing(3, 1),
    },
  },
  verify: {
    margin: theme.spacing(3, 15),
    padding: '5% 30px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    minHeight: '40vh',
    justifyContent: 'center',
    backgroundColor: theme.palette.white.main,
    [theme.breakpoints.down('md')]: {
      padding: '5% 25px',
      margin: theme.spacing(3, 1),
    },
  },
  submitButton: {
    maxWidth: theme.spacing(80),
    margin: theme.spacing(4, 'auto'),
    marginBottom: 0,
  },
  textInput: {
    margin: '10px auto',
    width: '90%',
  },
  helperText: {
    fontSize: 'small',
    margin: 0,
    marginBottom: theme.spacing(1),
    padding: '0 2%',
    height: 0,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    fontSize: '0.9em',
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  termsAndConditions: {
    fontSize: '0.8em',
    margin: '30px 0',
    fontStyle: 'italic',
    opacity: 0.8,
  },
  cardsContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    margin: '16px',
    marginLeft: '16px',
    width: '90%',
    columnGap: 30,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
      margin: '10px auto',
      rowGap: 10,
    },
  },
  infoCard: {
    padding: '5%',
    display: 'grid',
    gridTemplateColumns: '3fr 1fr 2fr',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  actionButtons: {
    width: '100%',
    textAlign: 'center',
    marginTop: 15,
    marginLeft: 15,
    display: 'flex',
    columnGap: 10,
    placeContent: 'left',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      alignItems: 'center',
      flexDirection: 'column',
      rowGap: 10,
    },
  },
  footerInfo: {
    display: 'flex',
    margin: 'auto',
    marginTop: 10,
    alignItems: 'center',
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      marginTop: 10,
      marginBottom: 80,
      marginLeft: 0,
    },
  },
  footerIcon: {
    display: 'flex',
    alignItems: 'center',
    placeContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      placeContent: 'center',
    },
  },
  footerText: {
    textAlign: 'left',
    paddingLeft: 5,
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  infoContainer: {
    paddingRight: '2.5%',
    [theme.breakpoints.down('md')]: {
      padding: 'auto',
      marginBottom: '5%',
    },
  },
  prepayAvatar: {
    width: '90px',
    [theme.breakpoints.down('md')]: {
      width: '70px',
    },
  },
  prepaySavingsContainer: {
    backgroundColor: theme.palette.background.defaultSecondary,
    borderRadius: '15px',
    [theme.breakpoints.down('md')]: {
      margin: '20px',
      padding: '20px',
    },
  },
  backButton: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    transition: '0.5s',
    '&:hover': {
      fontWeight: 'bold',
      color: theme.palette.secondary.main,
    },
    marginTop: '10px',
  },
  referralOverview: {
    paddingInline: '3%',
    backgroundColor: 'white',
    width: '90%',
    marginLeft: theme.spacing(4),
    display: 'grid',
    gridTemplateColumns: '2fr 3fr',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      paddingTop: '5%',
      gridTemplateColumns: '1fr',
      margin: 'auto',
    },
  },
  accordionSummary: {
    width: '20%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      width: '50%',
    },
  },
}));

export default useStyles;
