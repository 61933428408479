import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import BackgroundPic from '../../assets/background_forms_light.svg';

const useStyles = makeStyles((theme: Theme) => ({
  navBar: {
    height: '8%',
    boxShadow: '1px 1px 1px rgba(0, 0, 0, 0.5)',
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down('md')]: {
      height: '7%',
      padding: theme.spacing(2, 4),
    },
  },
  logo: {
    width: '140px',
    paddingTop: 5,
    [theme.breakpoints.down('md')]: {
      width: '110px',
    },
  },
  mobileNavigation: {
    width: '100%',
    position: 'fixed',
    bottom: 20,
    left: 10,
    zIndex: 1,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  mobileBottomNavBarAction: {
    paddingTop: '6px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
  },
  children: {
    minHeight: '92%',
    backgroundColor: theme.palette.background.defaultSecondary,
    backgroundImage: `url(${BackgroundPic})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    [theme.breakpoints.down('md')]: {
      minHeight: '93%',
    },
  },
  navButton: {
    display: 'flex',
    alignContent: 'center',
    width: 120,
    placeItems: 'center',
    paddingInline: 10,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.secondary.main,
      transition: '0.5s',

    },
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'right',
    },
  },
  arrowIcon: {
    '&:hover': {
      paddingBottom: 7,
      transition: '0.3s',
    },
  },
  floatingButton: {
    position: 'fixed',
    float: 'left',
    zIndex: 1,
    bottom: 10,
    left: 10,
    borderRadius: '50%',
    padding: 10,
    margin: 10,
    cursor: 'pointer',
    transition: '0.5s',
    [theme.breakpoints.down('md')]: {
      padding: 5,
      margin: 0,
      marginBottom: 0,
    },
  },
  speedDial: {
    '& .MuiSpeedDialIcon-icon': {
      color: theme.palette.white.main,
    },
  },
  backdrop: {
    zIndex: theme.zIndex.speedDial - 1,
    color: '#fff',
  },
}));

export default useStyles;
