import React from 'react';
import {
  Card, Fade, Tooltip, Typography,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CreditOverview from '../../../components/CreditOverview';
import useStyles from './styles';
import { LoanOverViewType } from '../../../types/account';
import { parseAmountWithCurrency } from '../../../utils/helpers';

type LoanSummaryProps = {
  loanData: LoanOverViewType
}

export default function LoanSummary(props: LoanSummaryProps) {
  const { loanData } = props;
  const classes = useStyles();
  return (
    <Fade in {... ({ timeout: 1500 })}>
      <div>
        <CreditOverview
          paidInstallments={loanData.paidQuotes}
          totalInstallments={loanData.periods}
          totalAmount={loanData.totalAmount}
          productType={loanData.productType}
        />
        <div className={classes.cardsContainer}>
          <Card className={classes.infoCard} sx={{ borderRadius: '15px' }}>
            <div>
              <Typography display="inline" variant="h6">
                Monto
                <b>
                  {' '}
                  total
                  {' '}
                  {(loanData.currency.abbreviation === 'CLF' && <span>(UF)</span>)}
                </b>
              </Typography>
            </div>
            <div style={{
              display: 'flex', alignItems: 'center', placeContent: 'left',
            }}
            >
              <Tooltip
                title={`El monto total a pagar del crédito se compone por el dinero que pediste
                          ($${loanData.loanAmount.toLocaleString('es-CL')})
                          , más el costo de originación, los impuestos y 
                          el pago de los intereses por el préstamo de dinero.`}
                arrow
              >
                <InfoOutlinedIcon style={{ fontSize: '20px' }} />
              </Tooltip>
            </div>
            <Typography variant="h5" fontWeight="bold" color="secondary">
              {parseAmountWithCurrency(loanData.currency, loanData.totalAmount, loanData.currency.abbreviation === 'CLF')}
            </Typography>

          </Card>
          <Card className={classes.infoCard} sx={{ borderRadius: '15px' }}>
            <div>
              <Typography display="inline" variant="h6">
                Monto
                <b>
                  {' '}
                  por pagar
                  {' '}
                  {(loanData.currency.abbreviation === 'CLF' && <span>(UF)</span>)}
                </b>
              </Typography>
            </div>
            <div />
            <Typography variant="h5" fontWeight="bold" color="secondary">
              {parseAmountWithCurrency(loanData.currency, loanData.unpaidAmount, loanData.currency.abbreviation === 'CLF')}
            </Typography>

          </Card>
          <Card className={classes.infoCard} sx={{ borderRadius: '15px' }}>
            <Typography display="inline" variant="h6" fontWeight="bold">
              Valor cuota
              {' '}
              {(loanData.currency.abbreviation === 'CLF' && <span>(UF)</span>)}
            </Typography>
            <div />
            <Typography display="inline" variant="h5" fontWeight="bold" color="secondary">
              {parseAmountWithCurrency(loanData.currency, loanData.installmentAmount, loanData.currency.abbreviation === 'CLF')}
            </Typography>

          </Card>
        </div>
        <div style={{ width: '90%', display: 'flex', placeContent: 'flex-end' }}>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
            <Typography
              display="inline"
              variant="body2"
              fontWeight="light"
              alignSelf="center"
            >
              Tasa de interés
              {' '}

            </Typography>
            <Typography
              display="inline"
              variant="body1"
              fontWeight="bold"
              color="secondary"
              align="center"
              alignSelf="center"
            >
              {loanData.interestRate.toString().replace('.', ',')}
              %
            </Typography>
          </div>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', alignItems: 'center' }}>
            <Typography
              display="inline"
              variant="body2"
              fontWeight="light"
            >
              CAE
              {' '}

            </Typography>

            <div style={{
              display: 'flex', alignItems: 'center', placeContent: 'left',
            }}
            >
              {' '}
              <Tooltip
                title={`La Carga Anual Equivalente (CAE) es la métrica que sirve para comparar los créditos como si fuesen entregados a un año
                            , en donde aparte de incluir la tasa de interés del crédito ${loanData.interestRate.toString().replace('.', ',')}%
                            , agrega todo el resto de gastos que comprenden la otorgación del crédito como costo de originación e impuestos
                            . Un menor CAE SIEMPRE significa un crédito más barato.`}
                arrow
              >
                <InfoOutlinedIcon style={{ fontSize: '15px' }} />
              </Tooltip>
            </div>

            <Typography
              display="inline"
              variant="body1"
              fontWeight="bold"
              color="secondary"
            >
              {loanData.cae.toString().replace('.', ',')}
              %
            </Typography>
          </div>
        </div>
      </div>
    </Fade>
  );
}
