import {
  Button,
  Box,
  Checkbox,
  Divider,
  FormControlLabel, List, ListItem,
  ListItemAvatar, ListItemText, Typography, useTheme,
} from '@mui/material';
import React from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { insuranceCalculator } from '../../utils/installmentCalculator';
import AlertDialog from '../AlertDialog';
import { getInsuranceLogo } from './helpers';
import { InsuranceType } from '../Simulator/types';
import { Currency } from '../../types/account';
import { parseAmountWithCurrency } from '../../utils/helpers';
import analytics from '../../utils/analytics';

type AvailableInsurancesType = {
    amount: number,
    periods: number,
    availableInsurances: InsuranceType[],
    handleSelectedAmountMarks: (mark: string) => void,
    checkedInsurances: {[key:InsuranceType['type']]: boolean},
    handleSetCheckedInsurances: (value: {[key:string]: boolean}) => void,
    showSimulator: boolean,
    hideFooter?: boolean,
    hideTitle?: boolean,
    iconSize?: 'small' | 'medium',
    readOnly?: boolean
    insuranceCost?: number
    currency: Currency,
    pageCall?: {
        name: string,
        category: string}
    displayFlex?: boolean
}

export default function InsurancesSelector(props:AvailableInsurancesType) {
  const {
    amount,
    periods,
    availableInsurances,
    handleSelectedAmountMarks,
    checkedInsurances,
    handleSetCheckedInsurances,
    showSimulator,
    hideFooter = false,
    hideTitle = false,
    iconSize = 'medium',
    readOnly = false,
    insuranceCost,
    currency,
    pageCall,
    displayFlex,
  } = props;

  const [originallyChecked, setOriginallyChecked] = React.useState({});
  const [initialChecked, setInitialChecked] = React.useState(checkedInsurances);
  const theme = useTheme();

  React.useEffect(() => {
    setOriginallyChecked(checkedInsurances);
  }, []);

  const handleSubmitModifiedInsurances = () => {
    const selected = Object.entries(checkedInsurances).filter((entries) => entries[1]).length > 0
      ? Object.entries(checkedInsurances).filter((entries) => entries[1]).map((entries) => entries[0]).toString() : 'none';
    const selectedForAnalytics = Object.entries(checkedInsurances)
      .filter((entries) => entries[1]).length > 0
      ? Object.entries(checkedInsurances)
        .filter((entries) => entries[1]).map((entries) => entries[0]) : [];
    const originallyCheckedArray = Object.entries(originallyChecked)
      .filter((entries) => entries[1]).length > 0
      ? Object.entries(originallyChecked)
        .filter((entries) => entries[1]).map((entries) => entries[0]) : [];
    handleSelectedAmountMarks(selected);
    if (selectedForAnalytics.toString() !== originallyCheckedArray.toString()) {
      analytics.track('Click Boton', {
        initiator: 'usuario',
        text: 'Modificar',
        insurancesSelected: selectedForAnalytics,
      });
    }
    setInitialChecked(checkedInsurances);
  };

  const onCloseAction = () => {
    handleSetCheckedInsurances(initialChecked);
  };

  React.useEffect(() => {
    Object.keys((checkedInsurances)).forEach((key) => {
      const ins = availableInsurances.find((insurance) => insurance.type === key);
      if (ins?.maxPeriods && ins?.minPeriods) {
        if (periods < ins.minPeriods && periods > ins.maxPeriods) {
          handleSetCheckedInsurances({ ...checkedInsurances, [key]: false });
          setInitialChecked({ ...checkedInsurances, [key]: false });
        }
      }
    });
  }, [periods]);

  const filteredCheckedInsurances = Object.entries(checkedInsurances)
    .filter((entry) => {
      const ins = availableInsurances.find((insurance) => insurance.type === entry[0]);
      if (ins?.maxPeriods && ins?.minPeriods) {
        if (periods < ins.minPeriods && periods > ins.maxPeriods) {
          return false;
        }
      }
      return true;
    });

  return (
    <Box
      style={displayFlex ? {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        width: '100%',
        columnGap: 10,
        alignItems: 'center',
        padding: 5,
      } : {
        padding: 1,
        borderRadius: 10,
      }}
      sx={{ marginBlock: { md: '5px' } }}
    >
      <div style={{
        borderRadius: 10,
        backgroundColor: theme.palette.background.defaultSecondary,
        padding: 1,
      }}
      >
        {!hideTitle && (
        <Typography
          style={{
            backgroundColor: theme.palette.tertiary.main,
            borderRadius: '10px 10px 0px 0px',
            paddingBlock: 5,
          }}
          variant="body2"
          component="div"
          color="primary"
        >
          Seguros asociados
        </Typography>
        )}
        <div style={{
          backgroundColor: theme.palette.background.default,
          borderRadius: 10,
        }}
        >
          <div style={{
            display: 'flex',
            columnGap: 10,
            rowGap: 10,
            justifyContent: 'center',
            paddingBlock: 10,
          }}
          >
            {Object.values(checkedInsurances).filter((value) => value).length > 0
              ? Object.entries(checkedInsurances).map(([key, value]) => (
                value
            && getInsuranceLogo({
              value: key,
              label: ((availableInsurances
                .find(
                  (insurance) => insurance.type === key,
                ) as InsuranceType).label),
              invertColors: true,
              iconSize,
            })
              ))
              : <Typography fontStyle="italic" color="GrayText" height={30}>(Ninguno)</Typography>}
          </div>

          {!hideFooter && (
          <Typography
            variant="caption"
            margin="auto"
            color="GrayText"
            maxWidth={250}
            component="div"
            paddingBottom={2}
          >
            {!showSimulator ? 'Al sobreponer el cursor aparecerán los nombre de los seguros asociados'
              : `(+ ${parseAmountWithCurrency(currency, insuranceCost || 0)} )`}
          </Typography>
          )}
        </div>
      </div>
      <div>
        <AlertDialog
          pageCall={pageCall}
          keepMounted
          dialogTitle={readOnly ? (
            <Typography
              align="center"
              fontWeight="bold"
            >
              Detalles
            </Typography>
          ) : (
            <Typography
              align="center"
              fontWeight="bold"
            >
              Modificar seguros asociados
            </Typography>
          )}
          submitButtonLabel="Modificar"
          closeAfterSubmit
          hideSubmitButton={readOnly}
          onCloseAction={onCloseAction}
          action={handleSubmitModifiedInsurances}
          button={showSimulator
            ? (
              <Button
                variant="text"
                sx={{ width: { md: 200 } }}
              >
                {readOnly ? <span>Ver detalles</span>
                  : <span>Modificar seguros</span>}
                <ArrowForwardIosIcon style={{
                  fontSize: 23,
                  marginLeft: 10,
                  color: theme.palette.primary.main,
                }}
                />
              </Button>
            )
            : <div />}
          dialogContent={(
            <>
              <List
                style={{
                  marginTop: 10, padding: 5, borderRadius: 10,
                }}
              >
                <ListItem
                  style={{ backgroundColor: theme.palette.tertiary.main, borderRadius: '10px 10px 0px 0px' }}
                  secondaryAction={(
                    <Typography
                      fontWeight="bold"
                      marginRight={6}
                      component="div"
                      color="primary"
                    >
                      Valor
                      {(currency.abbreviation === 'CLF' && (<span>(UF)</span>))}
                    </Typography>
)}
                >
                  <ListItemText
                    primary={(
                      <Typography
                        marginLeft={10}
                        component="div"
                        color="primary"
                        fontWeight="bold"
                      >
                        Concepto
                      </Typography>
)}
                  />
                </ListItem>
                <Divider />
                {filteredCheckedInsurances
                  .map(([key, value], index) => (
                    <div key={key}>
                      <ListItem
                        style={{

                          borderRadius: index === filteredCheckedInsurances.length - 1
                            ? '0px 0px 10px 10px' : undefined,
                        }}
                        secondaryAction={(
                          <FormControlLabel
                            value="top"
                            control={(
                          readOnly ? <div style={{ width: 20, height: 20 }} /> : (
                            <Checkbox
                              size="small"
                              onClick={() => handleSetCheckedInsurances(
                                { ...checkedInsurances, [key]: !value },
                              )}
                              checked={value}
                            />
                          )
    )}
                            label={(
                              <Typography
                                variant="caption"
                                fontWeight="bold"
                                component="div"
                                paddingTop={1}
                              >
                                {availableInsurances && periods
                              && parseAmountWithCurrency(currency, insuranceCalculator({
                                amount,
                                periods,
                                precision: currency.precision,
                                insuranceRate: ((availableInsurances
                                  .find(
                                    (insurance) => insurance.type === key
                                    && (
                                      (!insurance.maxPeriods && !insurance.minPeriods)
                                        || (periods >= insurance.minPeriods
                                          && periods <= insurance.maxPeriods)),
                                  ) as InsuranceType).premiumMultiplier),
                                type: key,
                              }))}
                              </Typography>
    )}
                            labelPlacement="start"
                          />
            )}
                      >
                        <ListItemAvatar style={{ minWidth: 0, width: 40 }}>
                          {getInsuranceLogo({
                            value: key,
                            label: ((availableInsurances
                              .find(
                                (insurance) => insurance.type === key,
                              ) as InsuranceType).label),
                            iconSize: 'medium',
                          })}
                        </ListItemAvatar>
                        <ListItemText
                          style={{ width: 200 }}
                          primary={(
                            <Typography
                              variant="body1"
                              fontWeight="bold"
                              component="div"
                            >
                              {((availableInsurances
                                .find(
                                  (insurance) => insurance.type === key,
                                ) as InsuranceType).label)}
                            </Typography>
)}
                          secondary={(
                            <Typography
                              variant="caption"
                              component="div"
                              sx={{ maxWidth: { xs: 150, sm: 200 } }}
                              textAlign="justify"
                              color="GrayText"
                              fontStyle="italic"
                            >
                              {
                        ((availableInsurances
                          .find(
                            (insurance) => insurance.type === key,
                          ) as InsuranceType).description)
}
                            </Typography>
                      )}
                        />
                      </ListItem>
                      <Divider />
                    </div>
                  ))}
              </List>
              <Typography variant="caption" component="div">
                El valor presentado es el valor
                que se agrega al monto total del crédito

              </Typography>
            </>
)}
        />
      </div>
    </Box>
  );
}

InsurancesSelector.defaultProps = {
  hideFooter: false,
  hideTitle: false,
  iconSize: 'medium',
  readOnly: false,
  insuranceCost: undefined,
  pageCall: undefined,
  displayFlex: false,
};
