import {
  makeStyles,
} from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme:Theme) => ({
  whereIsSerialNumber: {
    cursor: 'pointer',
    alignSelf: 'center',
    textDecoration: 'underline',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
}));

export default useStyles;
