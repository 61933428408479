import React from 'react';
import { Typography } from '@mui/material';
import DataTable from '../../../components/DataTable';
import { theme } from '../../../theme';

type DebtsDetailTableProps = {
  debtsDetail: {
    amount: number,
    debtTime: string,
    product?: string,
    institution: string,
    source: string,
    type: string}[]
}

const debtTimeLabels: {[key:string]: React.ReactElement} = {
  ACTIVE:
  <b style={{
    backgroundColor: 'lightgreen',
    padding: 5,
    borderRadius: 5,
    fontSize: 'small',
    color: 'darkgreen',
  }}
  >
    Al día
  </b>,
  INTERMEDIATE_DEBT:
  <b style={{
    backgroundColor: '#FFD580',
    padding: 5,
    borderRadius: 5,
    fontSize: 'small',
    color: 'darkorange',
  }}
  >
    Morosidad blanda
  </b>,
  HARD_DEBT:
  <b style={{
    backgroundColor: 'salmon',
    padding: 5,
    borderRadius: 5,
    fontSize: 'small',
    color: theme.palette.primary.main,
  }}
  >
    Morosidad dura

  </b>,
};
const productLabels: {[key:string]: string } = {
  directDebt: 'Deuda directa',
  indirectDebt: 'Deuda indirecta',
  Others: 'Otros créditos disponibles',
  credit: 'Línea de crédito disponible',
  morosidad: 'Morosidad',
  protesto: 'Protesto',
  mortgage: 'Hipotecario',
};

export default function DebtsDetailTable(props:DebtsDetailTableProps) {
  const { debtsDetail } = props;
  const totalDebt = debtsDetail.filter((d) => d.type !== 'protesto' && d.type !== 'morosidad').map((debt) => debt.amount);
  let totalAmount = '0';
  if (totalDebt.length > 0) {
    totalAmount = totalDebt.reduce((partialSum, a) => partialSum + a, 0).toLocaleString('es-CL');
  }
  return (
    <>
      <div>
        <Typography gutterBottom variant="h6">Mis deudas</Typography>
        <Typography
          gutterBottom
          variant="caption"
          color="primary"
          component="div"
        >
          Aquí verás tus deudas vigentes en el sistema

        </Typography>
      </div>
      { debtsDetail[0]?.product ? (
        <DataTable
          tableHeaders={['Entidad', 'Producto', 'Tipo', 'Deuda adquirida', 'Estado', 'Reportó']}
          tableBody={debtsDetail.map((debt, idx) => ({
            id: `_${idx}`,
            content: [debt.institution,
              debt.product as string,
              productLabels[debt.type],
              `$${debt.amount.toLocaleString('es-CL')}`,
              debtTimeLabels[debt.debtTime],
              debt.source],
          }))}
          fixedHeight={155}
          hideTableTitleAndAction
          totalsArray={[`TOTAL: ${debtsDetail.length}`, '--', '--', `$${totalAmount}`, '--', '--']}
        />
      ) : (
        <DataTable
          tableHeaders={['Entidad', 'Producto', 'Deuda adquirida', 'Estado', 'Reportó']}
          tableBody={debtsDetail.map((debt, idx) => ({
            id: `_${idx}`,
            content: [debt.institution,
              productLabels[debt.type],
              `$${debt.amount.toLocaleString('es-CL')}`,
              debtTimeLabels[debt.debtTime],
              debt.source],
          }))}
          fixedHeight={155}
          hideTableTitleAndAction
          totalsArray={[`TOTAL: ${debtsDetail.length}`, '--', `$${totalAmount}`, '--', '--']}
        />
      ) }
    </>
  );
}
