import React from 'react';
import { Grid } from '@mui/material';
import { ReactSession } from 'react-client-session';
import accountStyles from '../styles';
import Login from '../../../components/Login';
import analytics from '../../../utils/analytics';

function LoginForm() {
  const classes = accountStyles();
  const [givenIdNumber, setGivenIdNumber] = React.useState(undefined);
  const userData = ReactSession.get('User');
  React.useEffect(() => {
    if (userData) {
      const { idNumber } = JSON.parse(userData);
      setGivenIdNumber(idNumber);
    }
  }, [userData]);
  React.useEffect(() => {
    analytics.page('PU - LOGIN', 'INICIAR SESIÓN');
  }, []);
  return (
    <Grid
      container
      className={classes.root}
      justifyContent="center"
      alignItems="center"
    >
      <Login
        loginType="rut"
        endpoint="/login/member"
        givenIdNumber={givenIdNumber}
      />
    </Grid>
  );
}

export default LoginForm;
